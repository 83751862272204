/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'

require('es6-promise/auto')
require('whatwg-fetch')

import { addFlashMessage } from '../../../../../../api/app/behaviors/flash-messages'
import { PinturaEditorModal } from '@pqina/react-pintura'
import '@pqina/pintura/pintura.css'
import { editorDefaults } from '../../../../pages/EditMedia/atoms/ImageCropper'
import {
	setPlugins,
	plugin_crop,
	plugin_filter,
	plugin_annotate,
	plugin_sticker,
	createDefaultImageWriter,
} from '@pqina/pintura'
import { useDispatch } from 'react-redux'

// PINTURA
const pinturaConfig = {
	...editorDefaults,
	imageCropLimitToImage: false, // Allow crop outside of image, useful for ascing for 1X1 ratio
	// imageCropMinSize: { width: 960, height: 540 },
	// imageCropSizeMax: { width: 1920, height: 1080 },
	cropEnableImageSelection: true,
	cropEnableInfoIndicator: true,
	imageCropAllowInstructionZoom: true,
	imageCropResizeMatchImageAspectRatio: false,
	cropEnableRotateMatchImageAspectRatio: 'custom',
	cropEnableSelectPreset: false,
	// imageCropAspectRatio: 1,
	// cropSelectPresetOptions: [[1, 'Square']],
	imageBackgroundColor: [1, 1, 1],
	labelButtonExport: 'Crop & Save',
}

setPlugins(plugin_crop, plugin_filter, plugin_annotate, plugin_sticker)

const PinturaEditor = ({ uppy, setPinturaFile, pinturaFile, isThemeCover }) => {
	if (isThemeCover) {
		pinturaConfig.imageCropAspectRatio = 16 / 9
		pinturaConfig.imageCropMinSize = { width: 960, height: 540 }
		// Limit max image size
		pinturaConfig.imageWriter = createDefaultImageWriter({
			targetSize: {
				width: 1920,
				height: 1080,
			},
		})
	} else {
		pinturaConfig.imageCropAspectRatio = 1
		pinturaConfig.imageCropMinSize = { width: 300, height: 300 }
		pinturaConfig.imageCropLimitToImage = true
		// Limit max image size
		pinturaConfig.imageWriter = createDefaultImageWriter({
			targetSize: {
				width: 1200,
				height: 1200,
			},
		})
	}

	/** Import dispatch */
	const dispatch = useDispatch()

	const handlePinturaConfirm = async (output) => {
		// Add cropped file and the original source file to uppy
		let duplicates = [
			{
				...pinturaFile,
				data: output,
				type: pinturaFile.type,
				meta: {
					handledByPintura: true, // tell uppy not to trigger pintura again after cropping
					isCoverPhoto: true,
					isCoverDuplicate: false,
				},
			},
		]
		try {
			await (duplicates.forEach((file) => {
				try {
					uppy.addFile(file)
				} catch (err) {
					if (err.isRestriction) {
						// handle restrictions
						console.log('UPPY Restriction error:' + err)
					} else {
						// handle other errors
						console.log('UPPY Error:' + err)
					}
				}
			}),
			setPinturaFile(null))
		} catch (err) {
			console.log(err)
			dispatch(addFlashMessage('error', `Failed to set cover photo.`))
		}
	}

	if (!!pinturaFile) {
		return (
			<PinturaEditorModal
				{...pinturaConfig}
				src={pinturaFile}
				outputQuality={50}
				onHide={() => setPinturaFile(null)}
				onProcess={(result) => handlePinturaConfirm(result.dest)}
			/>
		)
	} else {
		return <></>
	}
}

PinturaEditor.defaultProps = {
	uppyInstance: undefined,
}

PinturaEditor.propTypes = {
	uppyInstance: PropTypes.object,
}

export default PinturaEditor
